@import 'media-query.mixins';

:root {
  --_defaultModalMapWidth: 100%;
  --_defaultModalMapHeight: 50vh;
}

.map {
  width: var(--modalMapWidth, --_defaultModalMapWidth);
  height: var(--modalMapHeight, --_defaultModalMapHeight);
}

.p-dialog.map-dialog {
  --buttonBackground: transparent;
  --buttonColor: #495057;
  --buttonBackgroundHover: transparent;
  --buttonColorHover: #495057;
  --buttonBorder: transparent;
  --buttonBorderHover: transparent;

  width: 80vw;
  @media (width > 992px) {
    width: 50vw;
  }
}

// This is overriding by our default button styles.
// This is an element that we don't have access to in the component.
// This element don't need any special styling across apps.
.p-dialog-header-close {
  border: none !important;
  border-radius: 3px !important;
  background-color: transparent !important;
  color: inherit !important;

  &:is(:hover, :focus) {
    background-color: #0000000b !important;
  }
}
