@import 'media-query.mixins';

#ush-showtimes-widget-wrapper {
  // WRAPPER
  --componentInCinemas-wrapper-padding: 0;
  --componentInCinemas-wrapper-background: none;
  --componentInCinemas-adressRowGap: 5px;
  --componentInCinemas-contentWrapper-background: var(--backgroundColor);
  --componentInCinemas-arrowColor: var(--brandColor);

  // DATES CAROUSEL
  --dates-carousel-background: var(--backgroundColor);

  // LOCATION SELECTOR
  --componentInCinemas-inputBackground: var(--colorLightGrey);
  --componentInCinemas-inputTextColor: black;
  --componentInCinemas-inputBorder: none;
  --componentInCinemas-nearMeBackground: var(--brandColor);
  --componentInCinemas-nearMeBackground--hover: var(--brandColor-hover);
  --componentInCinemas-nearMetextColor: var(--brandColor-contrast);
  --componentInCinemas-nearMetextColor--hover: var(--brandColor-contrast);
  --componentInCinemas-nearMetextBorder: 2px solid var(--brandColor);
  --componentInCinemas-nearMetextBorder--hover: 2px solid var(--brandColor-hover);

  // PERFORMANCES HEADER
  --componentInCinemas-showtimeCinemaHeight: 70px;
  --componentInCinemas-showtimeCinemaHeightMobile: 70px;
  --componentInCinemas-cinemaHeader-background: var(--brandColor);
  --componentInCinemas-cinemaHeader-background--hover: var(--brandColor-hover);
  --componentInCinemas-cinemaHeader-textColor: var(--brandColor-contrast);
  --componentInCinemas-cinemaHeader-textColor--hover: var(--brandColor-contrast);
  --componentInCinemas-cinemaHeaderActive-background: var(--backgroundColor);
  --componentInCinemas-cinemaHeaderActive-background--hover: var(--backgroundColor-hover);
  --componentInCinemas-cinemaHeaderActive-textColor: var(--backgroundColor-contrast);
  --componentInCinemas-cinemaHeaderActive-textColor--hover: var(--componentInCinemas-cinemaHeaderActive-textColor);
  --componentInCinemas-headerAdressHeight: 20px;
  --componentInCinemas-checkMsgRight: 0.5em;
  --componentInCinemas-chervonSize: 30px;
  --componentInCinemas-chervonSize--md: 37px;
  --componentInCinemas-chervonMarginLeft: 10px;
  --componentInCinemas-chervonMarginRight: 10px;
  --componentInCinemas-chervonBorder: none;
  --componentInCinemas-adreessColor: var(--backgroundColor-contrast);
  --componentInCinemas-adreessIconsColor: var(--backgroundColor-contrast);

  // PERFORMANCES
  --componentInCinemas-performances-gap: 10px;
  --componentInCinemas-performances-background: var(--backgroundColor);

  // DATE - color
  --componentInCinemas-date-textColor: var(--backgroundColor-contrast);
  --componentInCinemas-date-textColor--hover: var(--backgroundColor-contrast);
  --componentInCinemas-dateHighlight-textColor: var(--brandColor-contrast);
  --componentInCinemas-dateHighlight-textColor--hover: var(--brandColor-contrast);

  // DATE - background
  --componentInCinemas-date-background: var(--backgroundColor);
  --componentInCinemas-date-background--hover: var(--backgroundColor-hover);
  --componentInCinemas-dateHighlight-background: var(--brandColor);
  --componentInCinemas-dateHighlight-background--hover: var(--brandColor-hover);

  // DATE - border
  --componentInCinemas-date-border: none;
  --componentInCinemas-date-border--hover: none;
  --componentInCinemas-dateHighlight-border: none;
  --componentInCinemas-dateHighlight-border--hover: none;

  // DATE - border-bottom
  --componentInCinemas-date-borderBottom: 2px solid var(--brandColor);
  --componentInCinemas-date-borderBottom--hover: 2px solid var(--brandColor);
  --componentInCinemas-dateHighlight-borderBottom: 2px solid var(--brandColor);
  --componentInCinemas-dateHighlight-borderBottom--hover: 2px solid var(--brandColor-hover);

  --componentInCinemas-date-borderRadius: var(--borderRadius) var(--borderRadius) 0 0;

  // SUBMIT BUTTON
  --componentInCinemas-submitBackground: var(--brandColor);
  --componentInCinemas-submitBackground--hover: var(--brandColor-hover);
  --componentInCinemas-submitTextColor: var(--brandColor-contrast);
  --componentInCinemas-submitTextColor--hover: var(--brandColor-contrast);

  // NEAREST CITIES
  --componentInCinemas-nearestCity-headerTextColor: var(--textColor);
  --componentInCinemas-nearestCity-headerTextAlign: left;
  --componentInCinemas-nearestCity-headerTextTransform: initial;
  --componentInCinemas-nearestCity-listJustifyContent: flex-start;
  --componentInCinemas-nearestCity-listGap: 5px;
  --componentInCinemas-nearestCity-padding: 5px 10px;
  --componentInCinemas-nearestCity-buttonBackground: transparent;
  --componentInCinemas-nearestCity-buttonBackground--hover: var(--brandColor);
  --componentInCinemas-nearestCity-buttonTextColor: var(--textColor);
  --componentInCinemas-nearestCity-buttonTextColor--hover: var(--brandColor-contrast);
  --componentInCinemas-nearestCity-border: 2px solid var(--brandColor);
  --componentInCinemas-nearestCity-border--hover: 2px solid var(--brandColor);
  --componentInCinemas-nearestCity-buttonTextTransform: uppercase;
  --componentInCinemas-nearestCity-title-font-weight: inherit;
}

// ANIMATION ///////////////////////////////////////////////////////

// NOTE: change the backgrounds for the background color and text colors

@mixin apply-showtime-cinema-textColor($color) {
  .showtime-cinema span,
  .showtime-cinema fa-icon,
  .ush-cinema-check-msg fa-icon,
  .ush-cinema-check-msg span {
    color: $color;
  }
}

.cinema-location {
  fa-icon {
    color: var(--brandColor) !important;
  }
}

.ush-showtimes-wrapper {
  // overflow: hidden;
  display: flex;
  flex-direction: column;
  background: var(--componentInCinemas-wrapper-background);
  padding: var(--componentInCinemas-wrapper-padding);
  text-align: center;
  .ush-error-msg {
    color: var(--textColor);
  }
}
#ush-showtimes-widget-wrapper {
  .p-dialog-mask.p-component-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    .p-dialog {
      background-color: white;
      padding: 2vw;
    }
  }
}

.p-accordion-tab {
  border-radius: var(--borderRadius);
  overflow: hidden;
}
ush-arrow {
  width: 100%;
  height: 100%;
}
.p-accordion .p-accordion-header .p-accordion-header-link {
  box-shadow: none !important;
  border: none;
  padding: 0 !important;
  font-weight: inherit;
}
.p-accordion-header {
  background: none !important;
  .p-accordion-toggle-icon {
    display: none;
  }
}

.showtime-list {
  display: flex;
  row-gap: var(--componentInCinemas-performances-gap);
  flex-direction: column;
  padding: 0;
  li {
    display: flex;
    flex-flow: column;
    margin: 0;
    padding: 0;
    list-style: none;
    &,
    a {
      width: 100%;
    }
  }
}

.showtime-cinema-wrapper {
  width: 100%;
  &.old-flow {
    transition: 0.3s all cubic-bezier(0.86, 0, 0.07, 1);
    cursor: pointer;
    background: linear-gradient(
      0deg,
      var(--componentInCinemas-cinemaHeaderActive-background) 0%,
      var(--componentInCinemas-cinemaHeaderActive-background) 50%,
      var(--componentInCinemas-cinemaHeader-background) 50%,
      var(--componentInCinemas-cinemaHeader-background) 100%
    );
    background-size: 100% 200%;

    // Hover, Focus, Active effects
    @include apply-showtime-cinema-textColor(var(--componentInCinemas-cinemaHeader-textColor));
    &:hover,
    &:focus,
    &:active {
      &.active {
        background-position: bottom;

        &::after {
          background-position: bottom;
        }

        .ush-cinema-check-msg {
          background-position: bottom;
        }
      }
    }

    &:hover,
    &:focus,
    &:active {
      &,
      &::after,
      .ush-cinema-check-msg {
        background: linear-gradient(
          0deg,
          var(--componentInCinemas-cinemaHeaderActive-background--hover) 0%,
          var(--componentInCinemas-cinemaHeaderActive-background--hover) 50%,
          var(--componentInCinemas-cinemaHeader-background--hover) 50%,
          var(--componentInCinemas-cinemaHeader-background--hover) 100%
        );
        background-size: 100% 200%;
      }

      &.active {
        @include apply-showtime-cinema-textColor(var(--componentInCinemas-cinemaHeaderActive-textColor--hover));
      }

      &:not(.active) {
        @include apply-showtime-cinema-textColor(var(--componentInCinemas-cinemaHeader-textColor--hover));
      }
    }
    // Hover, Focus, Active effects

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      transition: 0.3s all cubic-bezier(0.86, 0, 0.07, 1);
      background: linear-gradient(
        0deg,
        var(--componentInCinemas-cinemaHeaderActive-background) 0%,
        var(--componentInCinemas-cinemaHeaderActive-background) 50%,
        var(--componentInCinemas-cinemaHeader-background) 50%,
        var(--componentInCinemas-cinemaHeader-background) 100%
      );
      background-size: 100% 200%;
      width: var(--componentInCinemas-checkMsgRight);
      height: 100%;
      content: '';
    }
    &:not(.active) {
      @include apply-showtime-cinema-textColor(var(--componentInCinemas-cinemaHeader-textColor));
    }
    &.active {
      @include apply-showtime-cinema-textColor(var(--componentInCinemas-cinemaHeaderActive-textColor));
      background-position: bottom;

      .showtime-cinema {
        background-position: bottom;
      }

      .ush-cinema-check-msg,
      .ush-cinema-check-msg-inside {
        background-position: bottom;
      }

      &::after {
        background-position: bottom;
      }
    }
    .showtime-cinema {
      height: var(--componentInCinemas-showtimeCinemaHeightMobile);
    }
  }
}

.showtime-cinema,
.ush-cinema-check-msg,
.ush-cinema-check-msg-inside,
.p-accordion-content {
  transition: 0.3s all cubic-bezier(0.86, 0, 0.07, 1);
}

.ush-cinema-check-msg {
  background: linear-gradient(
    0deg,
    var(--componentInCinemas-cinemaHeaderActive-background) 0%,
    var(--componentInCinemas-cinemaHeaderActive-background) 50%,
    var(--componentInCinemas-cinemaHeader-background) 50%,
    var(--componentInCinemas-cinemaHeader-background) 100%
  );
  background-size: 100% 200%;
}

/////////////////////////////////////////////////////////////////////
.p-accordion .p-accordion-content {
  border: none;
  padding: 0;
}

ush-dates-carousel {
  .p-button:disabled {
    display: none;
  }
  .p-button:disabled + swiper-container {
    margin-left: 0 !important;
  }
}

.showtime-cinema {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: var(
    --component-in-cinemas-showtime-cinema-padding,
    0.5em var(--componentInCinemas-checkMsgRight) 0.5em 0.5em
  );
  width: 100%;
  // background-color: none;

  @media (min-width: $md) {
    height: var(--componentInCinemas-showtimeCinemaHeight);
  }

  img {
    margin-right: 10px;
    border-radius: 100%;
    padding: 0;
    width: 45px;
    height: 45px;
  }
}

.ush-cinema-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .showtime-text-info {
    display: flex;
    flex-direction: row;
    line-height: normal;
  }
}

.showtime-cinema-wrapper {
  &.new-flow {
    justify-content: space-between;
    align-items: center;
    background: inherit;
    background-color: var(--componentInCinemas-contentWrapper-background);
    .showtime-text-info {
      text-align: left;
    }
    .ush-cinema-name {
      color: var(--brandColor) !important;
    }
    .showtime-cinema-info {
      display: flex;
      align-items: center;
    }
    .showtime-cinema {
      justify-content: space-between;
      align-items: flex-start;
      height: auto;
      .distance-literal {
        min-width: 60px;
        color: var(--brandColor);
      }
      @media screen and (max-width: 600px) {
        flex-direction: column;
      }
      &:hover {
        .ush-cinema-address {
          text-decoration: underline;
        }
      }
    }
    .ush-cinema-location-info {
      @media screen and (max-width: 600px) {
        display: block;
        width: 100%;
        text-align: right;
      }
    }
    .ush-cinema-close-map {
      fa-icon {
        margin-right: 5px;
        color: var(--componentInCinemas-adreessColor);
      }
    }
    .ush-cinema-name {
      text-wrap: wrap;
    }
    .ush-cinema-info {
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      fa-icon {
        margin-right: 5px;
        color: var(--componentInCinemas-adreessColor);
      }
      .ush-cinema-address {
        max-height: unset !important;
        overflow: unset !important;
        color: var(--componentInCinemas-adreessColor);
        font-size: 0.85em;
        text-align: left;
        text-overflow: unset !important;
        white-space: unset !important;
      }
    }
  }
}
.old-flow {
  .ush-cinema-name {
    overflow: hidden;
    font-size: inherit;
    text-overflow: ellipsis;
    text-transform: capitalize;
    white-space: nowrap;
  }
  .ush-cinema-check-msg-wrap .ush-cinema-check-msg {
    background: transparent !important;
  }
  @media (max-width: 600px) {
    .ush-cinema-name {
      white-space: normal !important;
    }
  }
}
.ush-cinema-address {
  transition: 0.3s all ease-in;
  max-height: var(--componentInCinemas-headerAdressHeight);
  overflow: hidden;
  font-weight: normal;
  font-size: inherit;
  text-overflow: ellipsis;
  white-space: nowrap;

  .ush-cinema-address-marker::after {
    content: '\00a0 ';
  }

  span {
    display: inline;
  }
}

.ush-cinema-check-msg-wrap {
  display: flex;
  position: absolute;
  right: 0;
  height: 100%;
  overflow: hidden;
}

.ush-cinema-check-msg {
  position: relative;
}

.ush-cinema-check-msg-inside {
  display: var(--component-in-cinemas-check-msg-inside-display, flex);
  column-gap: var(--componentInCinemas-check-msg-inside-column-gap);
  flex-direction: var(--componentInCinemas-check-msg-inside-flex-direction, row);
  flex-wrap: nowrap;
  align-items: center;
  padding: var(--component-in-cinemas-check-msg-inside-padding);
  height: 100%;
}

.ush-cinema-check-msg-chevron {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: var(--componentInCinemas-chervonMarginRight);
  margin-left: var(--componentInCinemas-chervonMarginLeft);
  border: var(--componentInCinemas-chervonBorder);
  border-radius: 50%;
  width: calc(var(--componentInCinemas-chervonSize));
  height: var(--componentInCinemas-chervonSize);

  @media screen and (min-width: 778px) {
    width: var(--componentInCinemas-chervonSize--md);
    height: var(--componentInCinemas-chervonSize--md);
  }
}

.ush-cinema-check-msg-message {
  display: var(--component-in-cinemas-check-msg-text-display, initial);
  position: relative;
  font-weight: normal;
  font-size: inherit;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.p-accordion-header:not(.p-highlight) {
  .ush-cinema-check-msg {
    left: calc(
      100% - var(--componentInCinemas-chervonSize) - var(--componentInCinemas-chervonMarginLeft) - var(
          --componentInCinemas-checkMsgRight
        )
    );

    @media screen and (min-width: 778px) {
      left: calc(
        100% - var(--componentInCinemas-chervonSize--md) - var(--componentInCinemas-chervonMarginLeft) - var(
            --componentInCinemas-checkMsgRight
          )
      );
    }
  }

  .ush-cinema-check-msg-chevron-up {
    display: none;
  }
}

.p-accordion-header.p-highlight {
  .ush-cinema-address {
    max-height: 0;
  }

  .ush-cinema-check-msg {
    left: calc(
      100% - var(--componentInCinemas-chervonSize) - var(--componentInCinemas-chervonMarginLeft) - var(
          --componentInCinemas-checkMsgRight
        )
    );

    @media screen and (min-width: 778px) {
      left: calc(
        100% - var(--componentInCinemas-chervonSize--md) - var(--componentInCinemas-chervonMarginLeft) - var(
            --componentInCinemas-checkMsgRight
          )
      );
    }
  }

  .ush-cinema-check-msg-chevron-down {
    display: none;
  }
}

.p-accordion-tab:hover .p-accordion-header:not(.p-highlight) .ush-cinema-check-msg {
  @media screen and (min-width: 778px) {
    left: 0;
  }
}

.cinema-location {
  display: flex;
  column-gap: 15px;
  row-gap: 5px;
  flex-wrap: wrap;
}

ush-location-selector {
  display: block;
  margin-bottom: 20px;
  @media all and (min-width: $md) {
    margin-bottom: 25px;
  }

  .input-icon.search-icon {
    display: none;
  }
  .input-icon.marker-icon {
    display: flex;
  }

  .input-icon {
    right: initial;
    left: 12px;
  }

  .location-input .p-autocomplete-input {
    border: var(--componentInCinemas-inputBorder);
    background: var(--componentInCinemas-inputBackground);
    padding: 0 0.5rem 0 33px;
    color: var(--componentInCinemas-inputTextColor);
  }

  .ush-near-me-button {
    transition: var(--buttonTransition);
    border: var(--componentInCinemas-nearMetextBorder, var(--buttonBorder));
    background: var(--componentInCinemas-nearMeBackground, var(--buttonBackground));
    color: var(--componentInCinemas-nearMetextColor, var(--buttonColor));
    &:hover,
    &:focus,
    &:active {
      border: var(--componentInCinemas-nearMetextBorder--hover, var(--buttonBorderHover));
      background: var(--componentInCinemas-nearMeBackground--hover, var(--buttonBackgroundHover));
      color: var(--componentInCinemas-nearMetextColor--hover, var(--brandColor));
    }
  }
}

.ush-showtimes-nearestCities-list {
  display: flex;
  column-gap: var(--componentInCinemas-nearestCity-listGap);
  row-gap: var(--componentInCinemas-nearestCity-listGap);
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: var(--componentInCinemas-nearestCity-listJustifyContent);
  padding: 0;
}

.ush-showtimes-nearestCities-item {
  list-style: none;
}

.ush-showtimes-nearestCities-title {
  display: flex;
  justify-content: space-between;
  color: var(--componentInCinemas-nearestCity-headerTextColor);
  font-weight: var(--componentInCinemas-nearestCity-title-font-weight);
  text-align: var(--componentInCinemas-nearestCity-headerTextAlign);
  text-transform: var(--componentInCinemas-nearestCity-headerTextTransform);
}

.ush-showtimes-nearestCities-item,
.ush-showtimes-nearestCities-title {
  .ush-showtime-nearestCities-btn {
    transition: 0.3s all ease-out;
    border: var(--componentInCinemas-nearestCity-border);
    border-radius: var(--borderRadius);
    background: var(--componentInCinemas-nearestCity-buttonBackground);
    padding: var(--componentInCinemas-nearestCity-padding);
    color: var(--componentInCinemas-nearestCity-buttonTextColor);
    font-family: inherit;
    text-transform: var(--componentInCinemas-nearestCity-buttonTextTransform);
    &:hover,
    &:focus,
    &:active {
      border: var(--componentInCinemas-nearestCity-border--hover);
      background: var(--componentInCinemas-nearestCity-buttonBackground--hover);
      color: var(--componentInCinemas-nearestCity-buttonTextColor--hover);
    }
  }
}
